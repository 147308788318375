.menu-open header.light svg {
  fill: #000;
}

.menu-open header.light .line {
  background-color: #000;
}

header {
  background-color: #ffffffe6;
}

header svg {
  fill: #000;
  margin-left: 21px;
}

header.light svg {
  fill: #fff;
}

header.light .line {
  background-color: #fff;
}

header.light.sticky svg {
  fill: #000;
}

header.light.sticky .line {
  background-color: #000;
}

.expandCircle-section__video {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.expandCircle-section__video-wrapper {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.expandCircle-section__video-wrapper img {
  width: 100%;
}

.header-left a {
  display: flex;
}

.trigger__aboutBanner {
  position: fixed;
  top: 20vw;
}

.colorize--red {
  color: #ff8772;
}

.colorize--turquoise {
  color: #8dd8d0;
}

.intro_img {
  clip-path: inset(100%);
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  transform: scale(1.8);
}

.intro_img01 {
  z-index: 47;
  width: 21.369vw;
  height: 16.563vw;
  top: -1.8vw;
  left: 38vw;
}

.intro_img02 {
  z-index: 20;
  width: 31.8vw;
  height: 25.75vw;
  top: 4.5vw;
  left: 50.5vw;
}

.intro_img03 {
  z-index: 30;
  width: 24.51vw;
  height: 24.542vw;
  top: 17.7vw;
  left: 61.15vw;
}

.intro_img04 {
  z-index: 40;
  width: 22.948vw;
  height: 14.073vw;
  top: 23.5vw;
  left: 41vw;
}

.intro_img05 {
  z-index: 50;
  width: 22.552vw;
  height: 20.271vw;
  top: 20.6vw;
  left: 20.6vw;
}

.intro_img06 {
  z-index: 50;
  width: 23.979vw;
  height: 18.958vw;
  top: 5.2vw;
  left: 27.17vw;
}

.intro_img_hold {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.intro_img_hold img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.mn_screen {
  z-index: 50;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.mn_screen .intro_hold_h1 {
  text-align: center;
  z-index: 65;
  opacity: 1;
  pointer-events: none;
  width: auto;
  margin: auto;
  display: inline-block;
  position: absolute;
  inset: 0;
  overflow: hidden;
  transform: translateY(0);
}

@media (width <= 768px) {
  .mn_screen .intro_hold_h1 {
    top: 50%;
    transform: translateY(-50%);
  }
}

.mn_screen .intro_hold_h1 .h2g {
  color: #a9abb5;
  letter-spacing: -1.664vw;
  opacity: 0;
  font: 22.188vw / 12.24vw Atyp;
  display: inline-block;
  position: relative;
  top: 50%;
  left: 0;
}

.mn_screen .intro {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.mn_screen .intro_hold {
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 40.208vw;
  margin: auto;
  display: flex;
  position: absolute;
  inset: 0;
}

.scroller {
  height: 100vh;
}

.mn_header {
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
}

@media (width >= 761px) {
  .mn_header {
    opacity: 0;
    pointer-events: none;
    transform: translateY(35px);
  }
}

#mns_id_set1 {
  position: relative;
  top: 2.604vw;
}

#mns_id_set1_ops {
  position: relative;
  top: 26.042vw;
}

#mns_id_set2 {
  position: relative;
  top: 5.208vw;
}

#mns_id_set2_ops, #mns_id_set3 {
  position: relative;
  top: 20.833vw;
}

#mns_id_set3_ops {
  position: relative;
  top: 44.271vw;
}

#mns_id_set4 {
  position: relative;
  top: 15.625vw;
}

#mns_id_set4_ops {
  position: relative;
  top: 31.25vw;
}

#mns_id_set5 {
  position: relative;
  top: 7.813vw;
}

#mns_id_set5_ops {
  position: relative;
  top: 41.667vw;
}

#mns_id_set6 {
  position: relative;
  top: 0;
}

#mns_id_set6_ops {
  position: relative;
  top: 26.042vw;
}

#mns_id_set7 {
  position: relative;
  top: 18.229vw;
}

#mns_id_set7_ops {
  position: relative;
  top: 33.854vw;
}

@media (width >= 768px) {
  .animateWords span {
    opacity: .2;
  }
}

@media (width >= 961px) {
  .hdn {
    height: 100vh;
    overflow: hidden;
  }
}

.pin-spacer {
  inset: auto !important;
}

.mn_screen {
  left: 0 !important;
}

footer img {
  height: 21px;
}

footer ul li img {
  width: 32px;
  height: 32px;
}

.third-image-section .third-image-row video.dm-first-video {
  object-fit: cover;
  width: 14.8vw;
  height: 13.8vw;
}

.third-image-section .third-image-row video.dm-coffe-video {
  width: 33.8vw;
  height: 19.8vw;
}

.third-image-section .third-image-row video.dm-cooking-video {
  width: 35.8vw;
  height: 19.8vw;
}

.new-standard-section .new-standard-two {
  justify-content: flex-end;
  gap: 50px;
  margin-right: 10%;
  display: flex;
}

@media (width <= 768px) {
  .our-mission-section {
    padding-top: 60px;
  }

  .our-mission-row-one {
    max-width: unset;
  }
}

.footer-right-links a {
  font-size: 24px;
  line-height: 28px;
}

.crafting-living-left .crafting-living-content p.dm-text {
  color: #000;
  -webkit-text-fill-color: unset;
  background: unset;
  max-width: 470px;
  margin-top: 40px;
  font-size: 17px;
  line-height: 22px;
}

.image-and-text-hero-section img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.image-and-text-hero-section h2 {
  color: #fff;
}

.upgradedSection .new-standard-two {
  margin-top: 40px;
}

.upgradedSection--wrapper {
  justify-content: center;
  align-items: center;
  margin-top: 3%;
  display: flex;
}

.upgradedSection--wrapper .new-standard-image {
  max-width: 370px;
  font-size: 0;
}

.upgradedSection--wrapper h2 {
  color: #8dd8d0;
  margin-right: 3%;
}

.maecenas-maximus-item .video-content-text.bottom-text {
  top: 70%;
}

.approachBanner img {
  width: 100%;
}

.approachBanner h2 {
  color: #fff;
}

.communityBanner .video-and-text-wrapper, .approachBanner .video-and-text-wrapper {
  max-height: 650px;
}

@media (width <= 980px) {
  .dm-hide-mobile {
    display: none !important;
  }

  .dm-show-mobile {
    display: contents !important;
  }
}

@media (width >= 981px) {
  .dm-show-mobile {
    display: none !important;
  }
}

.child-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.child-center img {
  max-width: 86%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hero-section {
  position: relative;
}

.footer-small-text {
  color: #111;
  text-align: left;
  font-family: HalenoirCompact-ExtraLight, sans-serif;
  font-size: 36px;
  font-weight: 300;
  display: inline-block;
}

@media (width <= 768px) {
  .footer-small-text {
    font-size: 20px;
  }
}

.h2-line-height {
  line-height: 150px;
}

@media (width <= 768px) {
  .h2-line-height {
    line-height: 48px;
  }
}

@media (width >= 769px) and (width <= 1023px) {
  .h2-line-height {
    line-height: 75px;
  }
}

@media (width >= 1024px) and (width <= 1440px) {
  .h2-line-height {
    line-height: 9vw;
  }
}

@media (width <= 768px) {
  .responsibility-text {
    text-align: right;
    margin-top: 1.7vw;
    margin-right: 0;
  }
}

.expertiseHero {
  position: relative;
}

.expertiseHero .expertiseHero-section__heading {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.expertiseHero .expertiseHero-section__heading h2 {
  position: relative;
  left: 25%;
}

@media (width <= 991px) {
  .expertiseHero {
    background-color: #0003;
    padding: 156px 20px 93px;
  }

  .expertiseHero .expertiseHero-section__banner {
    inset: unset;
    z-index: -1;
    position: absolute;
    inset: 0;
  }

  .expertiseHero .expertiseHero-section__heading {
    max-height: unset;
    transform: unset;
    position: relative;
  }

  .expertiseHero .expertiseHero-section__heading h2 {
    transform: unset;
    position: relative;
    left: 20px;
  }

  .expertiseHero .expertiseHero-section__heding img {
    object-fit: cover;
    height: 100%;
  }
}

.approachBanner {
  position: relative;
  overflow: hidden;
}

.approachBanner .approachBanner-section__heading {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.approachBanner .approachBanner-section__heading h2 {
  position: relative;
  left: 25%;
}

@media (width <= 991px) {
  .approachBanner {
    background-color: #0003;
    padding: 156px 20px 93px;
  }

  .approachBanner .approachBanner-section__banner {
    inset: unset;
    z-index: -1;
    position: absolute;
    inset: 0;
  }

  .approachBanner .approachBanner-section__heading {
    max-height: unset;
    transform: unset;
    overflow: unset;
    position: relative;
  }

  .approachBanner .approachBanner-section__heading h2 {
    transform: unset;
    position: relative;
    left: 20px;
  }

  .approachBanner .approachBanner-section__heding img {
    object-fit: cover;
    height: 100%;
  }
}

.communityBanner {
  position: relative;
  overflow: hidden;
}

.communityBanner .communityBanner-section__heading {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.communityBanner .communityBanner-section__heading h2 {
  position: relative;
  left: 25%;
}

@media (width <= 991px) {
  .communityBanner {
    background-color: #0003;
    padding: 156px 20px 93px;
  }

  .communityBanner .communityBanner-section__banner {
    inset: unset;
    z-index: -1;
    position: absolute;
    inset: 0;
  }

  .communityBanner .communityBanner-section__heading {
    max-height: unset;
    transform: unset;
    overflow: unset;
    position: relative;
  }

  .communityBanner .communityBanner-section__heading h2 {
    transform: unset;
    position: relative;
    left: 20px;
  }

  .communityBanner .communityBanner-section__heding img {
    object-fit: cover;
    height: 100%;
  }
}

@media (width <= 768px) {
  .expandCircle-section {
    background-color: #0003;
  }

  .expandCircle-section .expandCircle-section__heading h2 {
    margin-bottom: 0;
  }

  .expandCircle-section .expandCircle-section__video-wrapper img {
    object-fit: cover;
    height: 100%;
  }

  .expandCircle-section .new-standard-two {
    color: #fff;
    justify-content: flex-start !important;
  }

  .expandCircle-section .new-standard-three, .expandCircle-section .new-standard-one {
    color: #fff;
    margin-bottom: 0;
  }
}

.logo-video-section__letter h2:after {
  content: "";
  height: 150px;
  display: block;
}

.logo-video-wrapper div.full-stop {
  flex-shrink: 0;
  width: 120px;
  max-width: 80px;
  height: 120px;
  max-height: 80px;
  margin-bottom: 14.6%;
  margin-right: 60px;
  display: block;
  position: relative;
}

.logo-video-wrapper div.full-stop:after {
  content: "";
  height: 150px;
  display: block;
}

@media (width <= 1281px) {
  .logo-video-wrapper div.full-stop {
    width: 70px;
    height: 70px;
    margin-bottom: 22.6%;
  }
}

@media (width <= 768px) {
  .logo-video-wrapper div.full-stop {
    width: 25px;
    height: 25px;
    margin-bottom: 32.6%;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.top-2 {
  padding-top: 3vw;
}

.dolor-sitamet-text-2 {
  margin-top: 15.5vw;
}

@media (width <= 768px) {
  .dolor-sitamet-text-2 {
    margin-left: 2vw;
  }
}

.dolor-sitamet-text-2 p {
  margin-top: 3vw;
}

.dolor-sitamet-img-2 {
  margin-top: 35vw;
  display: block;
}

.dolor-sitamet-img-2 img {
  width: 18.3vw;
  height: 12.9vw;
  margin-bottom: -12.5vw;
}

.dolor-sitamet-img-4 {
  bottom: -7.3vw;
  left: -4.9vw;
}

.dolor-sitamet-img-4 img {
  width: 22.7vw;
  height: 13.4vw;
}

.dolor-sitamet-3 {
  padding-top: 6.9vw;
}

.new-standard-section {
  position: relative;
}

.new-standard-section .expandCircle-section__video {
  z-index: -1;
  position: absolute;
  inset: 0;
}

.menu-open .main-menu {
  overflow: hidden scroll;
}

@media (width <= 768px) {
  .menu-btn {
    margin-right: 10px;
  }
}

.our-values-section {
  padding: 8vw 7.62vh;
  overflow: hidden;
}

@media (width >= 768px) and (width <= 1250px) {
  .our-values-section {
    padding: 8vw 5vw;
  }
}

@media (width <= 768px) {
  .our-values-section {
    width: 100%;
    max-width: 768px;
    margin: 0 auto;
    padding: 8vw 5vw;
  }

  .our-mission-img-2, .our-mission-img-1 {
    position: relative;
  }
}

@media (width <= 767px) {
  .new-standard-one {
    margin-bottom: 40px;
  }

  .new-standard-two {
    margin-bottom: 60px;
  }
}

@media (width >= 530px) and (width <= 767px) {
  .new-standard-three.dm-text {
    margin-bottom: 0;
  }
}

@media (width >= 768px) and (width <= 1440px) {
  .our-values-text.dm-text-our-values h3 {
    font-size: 3.45vw;
  }
}

@media (width <= 530px) {
  .our-values-text.dm-text-our-values h3 {
    font-size: 25px;
  }

  .community-hero-image.dm-mobile-image img {
    margin-top: 16vh;
  }
}

@media (width <= 768px) {
  .our-values-first-row.dm-our-aproach-text .text-character-a {
    margin-left: 0;
  }
}

.our-values-content-two-wrapper {
  display: grid;
}

.our-values-content-two.dm-our-values-content-2 .our-values-content-5 {
  float: right;
  margin-bottom: 30%;
  margin-left: 30%;
}

.our-values-content-two.dm-our-values-content-2 .our-values-content-5 br {
  display: block;
}

.color-white {
  color: #fff;
}

.founder-items a {
  color: #ff8772;
}

.founder-items a:hover {
  color: #fff;
}

.ourApproachPage .our-values-content-two-wrapper .our-values-content-4 {
  max-width: 75%;
  margin-top: 15%;
  margin-bottom: 15%;
}

.ourApproachPage .our-values-content-one-wrapper {
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  display: flex;
}

.ourApproachPage .our-values-content-one-wrapper .our-values-content-1, .ourApproachPage .our-values-content-one-wrapper .our-values-content-2 {
  max-width: 75%;
}

.ourApproachPage .our-values-content-one-wrapper .our-values-content-2 {
  margin-left: 25%;
}

.ourApproachPage .our-values-content-two .our-values-text-character {
  height: 100%;
  position: relative;
}

.ourApproachPage .our-values-content-two .our-values-text-character .text-character-t {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.ourApproachPage .our-values-content-two, .ourApproachPage .our-values-content-one {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: 50px;
  display: grid;
}

@media (width <= 1280px) {
  .ourApproachPage .our-values-content-two, .ourApproachPage .our-values-content-one {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ourApproachPage .our-values-first-row {
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 110px;
  display: grid;
}

@media (width <= 1280px) {
  .ourApproachPage .our-values-first-row {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.ourApproachPage .our-values-section {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;
}

.ourApproachPage .text-character-t, .ourApproachPage .text-character-p {
  max-width: 650px;
  height: auto;
  margin-left: 0;
  font-size: 0;
}

.ourApproachPage .text-character-a {
  max-width: 650px;
  height: auto;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: 0;
  font-size: 0;
}

@media only screen and (width <= 870px) {
  .ourApproachPage .content-details, .ourApproachPage .content-title {
    margin-bottom: 50px;
  }

  .ourApproachPage .our-values-text h3 {
    height: unset;
    font-size: 32px;
  }

  .ourApproachPage .our-values-first-row {
    margin-top: 50px;
  }

  .ourApproachPage .our-values-content-one, .ourApproachPage .our-values-first-row {
    grid-template-columns: unset;
  }

  .ourApproachPage .our-values-text-character {
    display: none;
  }

  .ourApproachPage .our-values-content-one-wrapper .our-values-content-1 {
    max-width: unset;
  }

  .ourApproachPage .text-character-a {
    margin-top: 0;
    margin-bottom: 50px;
  }

  .ourApproachPage .our-values-content-1 .content-title {
    margin-bottom: 50px;
    font-size: 36px;
    font-weight: 300;
    line-height: 38.88px;
  }

  .ourApproachPage .our-values-content-one-wrapper .our-values-content-2 {
    max-width: unset;
    margin-top: 50px;
    margin-left: 0;
  }

  .ourApproachPage .our-values-content-two-wrapper .our-values-content-4 {
    max-width: unset;
    margin-top: 0;
  }

  .ourApproachPage .our-values-content-two {
    grid-template-columns: unset;
  }

  .ourApproachPage .our-values-content-two .our-values-text-character .text-character-t {
    position: relative;
  }

  .ourApproachPage .our-values-content-two.dm-our-values-content-2 .our-values-content-5 {
    margin-bottom: 0;
    margin-left: 0;
  }
}

@media only screen and (width >= 871px) {
  .showMobile {
    display: none;
  }

  .showDesktop {
    display: block;
  }
}

.communityPage .social-responsibility-section {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 50px;
}

@media only screen and (width <= 870px) {
  .communityPage .dolor-sitamet-section {
    padding: 79px 0;
  }

  .communityPage .dolor-sitamet-section h2 {
    margin-bottom: 30px;
    font-size: 54px;
    font-weight: 600;
    line-height: 48px;
  }

  .communityPage .dolor-sitamet-section p {
    max-width: unset;
    margin-top: 0;
    margin-left: 0;
  }

  .communityPage .dolor-sitamet-text-1 {
    margin-top: 0;
    margin-left: 0;
  }

  .communityPage .dolor-sitamet-text-2 {
    margin-top: 0;
    margin-bottom: 50px;
    margin-left: 0;
  }

  .communityPage .dolor-sitamet-text-1, .communityPage .dolor-sitamet-text-2, .communityPage .dolor-sitamet-text-3 {
    padding: 0 30px;
  }

  .communityPage .dolor-sitamet-text-3 p {
    margin-bottom: 50px;
    padding-top: 0;
  }

  .communityPage .dolor-sitamet-img-3 {
    width: 80%;
    margin-bottom: 50px;
    margin-left: auto;
  }

  .communityPage .dolor-sitamet-img-3, .communityPage .dolor-sitamet-img-4 {
    position: static;
  }

  .communityPage .dolor-sitamet-img-2 img, .communityPage .dolor-sitamet-img-3 img, .communityPage .dolor-sitamet-img-4 img {
    width: 100%;
    height: auto;
  }

  .communityPage .dolor-sitamet-1 {
    flex-direction: column;
  }

  .communityPage .dolor-sitamet-3 {
    max-width: unset;
    padding-top: 0;
  }

  .communityPage .dolor-sitamet-3, .communityPage .dolor-sitamet-2 {
    flex-direction: column-reverse;
    display: flex;
  }

  .communityPage .dolor-sitamet-2 {
    margin-bottom: 50px;
  }

  .communityPage .dolor-sitamet-img-2 {
    width: 80%;
    margin-top: 0;
  }

  .communityPage .dolor-sitamet-img-2 img {
    margin-bottom: 0;
  }

  .communityPage .dolor-sitamet-img-4 {
    width: 80%;
    margin-left: auto;
  }

  .communityPage .dolor-sitamet-img-1, .communityPage .dolor-sitamet-img-2, .communityPage .dolor-sitamet-img-3, .communityPage .dolor-sitamet-img-4 {
    height: 300px;
  }

  .communityPage .dolor-sitamet-img-1 img, .communityPage .dolor-sitamet-img-2 img, .communityPage .dolor-sitamet-img-3 img, .communityPage .dolor-sitamet-img-4 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin-top: 0;
  }

  .communityPage .dolor-sitamet-img-1 {
    width: 80%;
    margin-bottom: 50px;
    overflow: hidden;
  }

  .communityPage .community-hero-image img {
    width: 100%;
    height: auto;
  }
}

@media only screen and (width <= 420px) {
  .communityPage .social-responsibility-section {
    padding: 50px 10px;
  }

  .communityPage .dolor-sitamet-section h2 {
    font-size: 40px;
  }
}

.homePage .future-living-section {
  background: #111;
}

.homePage .future-living-section .container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 100px 50px;
}

@media only screen and (width <= 870px) {
  .homePage .future-living-section .container {
    padding: 50px 30px;
  }

  .homePage .future-living-wrapper {
    justify-content: space-evenly;
    align-items: center;
    display: flex;
  }

  .homePage .future-living-wrapper h2 {
    right: unset;
    position: unset;
    transform: unset;
  }

  .homePage .future-living-wrapper .future-living-image {
    margin-left: 0;
    margin-right: unset;
  }

  .homePage .crafting-living-deatils {
    margin-top: 150px;
  }

  .homePage .crafting-living-deatils, .homePage .crafting-living-content {
    width: 100vw;
  }

  .homePage .new-standard-section {
    padding-top: 0;
  }

  .homePage .new-standard-section .new-standard-two {
    justify-content: flex-end;
    gap: unset;
    white-space: pre;
    margin-bottom: 0;
    margin-right: 0;
    display: flex;
  }

  .homePage .new-standard-one {
    justify-content: flex-end;
    display: flex;
  }

  .aboutUsPage .about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
    width: 100vw;
    margin-top: 100px;
  }

  .aboutUsPage .our-purpose-line-two {
    justify-content: start;
    align-items: start;
    padding: 0 20px 69px;
  }

  .ourExpertisePage .view-our-team-image {
    max-width: unset;
    left: unset;
    transform: unset;
    margin: 30px;
  }

  .ourExpertisePage .view-our-team-content-4, .ourExpertisePage .view-our-team-content-3 {
    max-width: unset;
  }

  .ourExpertisePage .view-our-team-section {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px 20px;
  }

  .hideMobile {
    display: none !important;
  }

  .showMobile {
    display: block !important;
  }
}

.staticPage .container {
  max-width: 980px;
  margin: 0 auto;
}

.staticPage #main h1 {
  font-size: 32px;
}

.staticPage #main .container {
  padding: 0 20px;
}

.staticPage #main .container:last-child {
  margin-bottom: 100px;
}

.staticPage #main .container:first-child {
  margin-top: 120px;
}

@media (width >= 1024px) {
  .staticPage #main .container:first-child {
    margin-top: 200px;
  }
}

.staticPage #main h2 {
  font-size: 24px;
}

.staticPage #main h1, .staticPage #main h2, .staticPage #main h3, .staticPage #main h4, .staticPage #main h5, .staticPage #main h6, .staticPage #main ul, .staticPage #main p {
  margin-top: 40px;
}

.staticPage #main a {
  color: #111;
  text-decoration: underline;
}

.staticPage #main li, .staticPage #main p {
  line-height: 1.4;
}

.staticPage #main ul {
  padding-left: 40px;
}

.staticPage #main ul li {
  list-style: initial;
}
/*# sourceMappingURL=index.5a1ac3e9.css.map */
