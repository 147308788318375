.menu-open {
	header {
		&.light {
			svg {
				fill: #000;
			}
			.line {
				background-color: #000;
			}
		}
	}
}
header {
	background-color: rgba(255, 255, 255, 0.9);
	svg {
		fill: #000;
		margin-left: 21px;
	}
	&.light {
		svg {
			fill: #fff;
		}
		.line {
			background-color: #fff;
		}

		&.sticky {
			svg {
				fill: #000;
			}
			.line {
				background-color: #000;
			}
		}
	}
}

.expandCircle-section__video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;

	&-wrapper {
		object-fit: cover;
		width: 100%;
		height: 100%;

		img {
			width: 100%;
		}
	}
}

.header-left {
	a {
		display: flex;
	}
}

.trigger__aboutBanner {
	position: fixed;
	top: 20vw;
}
.colorize {
	&--red {
		color: #ff8772;
	}
	&--turquoise {
		color: #8dd8d0;
	}
}

.intro_img {
	position: absolute;
	top: 0;
	left: 0;
	margin: auto;
	clip-path: inset(100% 100% 100% 100%);
	transform: scale(1.8);
}

/* .hero-image-1 {
  top: 7.2vw;
  left: 35vw;
  width: 27.2vw;
  z-index: 30;
} */

.intro_img01 {
	z-index: 47;
	width: 21.369vw;
	height: 16.563vw;
	top: -1.8vw;
	left: 38vw;
}
/* .hero-image-2 {
  top: 11.5vw;
  left: 49.5vw;
  width: 38.8vw;
  z-index: 20;
}*/
.intro_img02 {
	width: 31.8vw;
	height: 25.75vw;
	top: 4.5vw;
	left: 50.5vw;
	z-index: 20;
}
// .hero-image-3 {
// 	top: 29.7vw;
// 	left: 64.15vw;
// 	width: 27.23vw;
// 	z-index: 80;
//   }
.intro_img03 {
	z-index: 30;
	width: 24.51vw;
	height: 24.542vw;
	top: 17.7vw;
	left: 61.15vw;
}
// .hero-image-4 {
// 	top: 37.5vw;
// 	left: 38.44vw;
// 	width: 28.8vw;
// 	z-index: 90;
//   }
.intro_img04 {
	z-index: 40;
	width: 22.948vw;
	height: 14.073vw;
	top: 23.5vw;
	left: 41vw;
}

// .hero-image-5 {
// 	top: 32.6vw;
// 	left: 13.6vw;
// 	width: 27.12vw;
//   }
.intro_img05 {
	z-index: 50;
	width: 22.552vw;
	height: 20.271vw;
	top: 20.6vw;
	left: 20.6vw;
}
// .hero-image-6 {
// 	top: 14.2vw;
// 	left: 21.17vw;
// 	width: 30.3vw;
// 	z-index: 50;
// }
.intro_img06 {
	z-index: 50;
	width: 23.979vw;
	height: 18.958vw;
	top: 5.2vw;
	left: 27.17vw;
}

.intro_img_hold {
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
}

.intro_img_hold img {
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
	width: 100%;
}

.mn_screen {
	position: relative;
	height: 100vh;
	overflow: hidden;
	z-index: 50;

	.intro_hold_h1 {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		bottom: auto;
		top: 0;
		width: auto;
		bottom: 0;
		text-align: center;
		display: inline-block;
		overflow: hidden;
		z-index: 65;
		opacity: 1;
		transform: translateY(0);
		pointer-events: none;

		@media (max-width: 768px) {
			top: 50%;
			transform: translateY(-50%);
		}

		.h2g {
			position: relative;
			display: inline-block;
			color: #a9abb5;
			font: normal 22.188vw / 12.24vw Atyp;
			letter-spacing: -1.664vw;
			left: 0;
			top: 50%;
			opacity: 0;
		}
	}
}

.mn_screen .intro {
	position: absolute;
	height: 100vh;
	left: 0;
	top: 0;
	width: 100vw;
	overflow: hidden;
}

.mn_screen .intro_hold {
	align-items: center;
	display: flex;
	justify-content: center;
	position: relative;
	top: 0;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	margin: auto;
	width: 100vw;
	height: 40.208vw;
	bottom: 0;
	margin: auto;
}

.scroller {
	height: 100vh;
}

.mn_header {
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;

	@media (min-width: 761px) {
		transform: translateY(35px);
		opacity: 0;
		pointer-events: none;
	}
}

#mns_id_set1 {
	position: relative;
	top: 2.604vw;
}

#mns_id_set1_ops {
	position: relative;
	top: 26.042vw;
}

#mns_id_set2 {
	position: relative;
	top: 5.208vw;
}

#mns_id_set2_ops {
	position: relative;
	top: 20.833vw;
}

#mns_id_set3 {
	position: relative;
	top: 20.833vw;
}

#mns_id_set3_ops {
	position: relative;
	top: 44.271vw;
}

#mns_id_set4 {
	position: relative;
	top: 15.625vw;
}

#mns_id_set4_ops {
	position: relative;
	top: 31.25vw;
}

#mns_id_set5 {
	position: relative;
	top: 7.813vw;
}

#mns_id_set5_ops {
	position: relative;
	top: 41.667vw;
}

#mns_id_set6 {
	position: relative;
	top: 0;
}

#mns_id_set6_ops {
	position: relative;
	top: 26.042vw;
}

#mns_id_set7 {
	position: relative;
	top: 18.229vw;
}

#mns_id_set7_ops {
	position: relative;
	top: 33.854vw;
}

.animateWords {
	@media (min-width: 768px) {
		span {
			opacity: 0.2;
		}
	}
}

@media (min-width: 961px) {
	.hdn {
		height: 100vh;
		overflow: hidden;
	}
}

// wh > hack for scrollbar
.pin-spacer {
	inset: auto !important;
}
.mn_screen {
	left: 0px !important;
}

footer {
	img {
		height: 21px;
	}
	ul li {
		img {
			width: 32px;
			height: 32px;
		}
	}
}
/*********************  UPDATES  21 03 2024  ********************************/

.third-image-section .third-image-row video {
	&.dm-first-video {
		object-fit: cover;
		width: 14.8vw;
		height: 13.8vw;
	}

	&.dm-coffe-video {
		width: 33.8vw;
		height: 19.8vw;
	}

	&.dm-cooking-video {
		width: 35.8vw;
		height: 19.8vw;
	}
}

.new-standard-section .new-standard-two {
	justify-content: flex-end;
	margin-right: 1px;
	display: flex;
	gap: 50px;
	margin-right: 10%;
}

@media (max-width: 768px) {
	.our-mission-section {
		padding-top: 60px;
	}
	.our-mission-row-one {
		max-width: unset;
	}
}

.footer-right-links a {
	font-size: 24px;
	line-height: 28px;
}

.crafting-living-left .crafting-living-content p.dm-text {
	font-size: 17px;
	color: #000;
	-webkit-text-fill-color: unset;
	background: unset;
	max-width: 470px;
	line-height: 22px;
	margin-top: 40px;
}

.image-and-text-hero-section {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	h2 {
		color: #fff;
	}
}

.upgradedSection {
	.new-standard-two {
		margin-top: 40px;
	}
	&--wrapper {
		justify-content: center;
		align-items: center;
		display: flex;
		margin-top: 3%;

		.new-standard-image {
			max-width: 370px;
			font-size: 0;
		}

		h2 {
			color: #8dd8d0;
			margin-right: 3%;
		}
	}
}

.maecenas-maximus-item .video-content-text.bottom-text {
	top: 70%;
}

.approachBanner {
	img {
		width: 100%;
	}
	h2 {
		color: #fff;
	}
}

.communityBanner,
.approachBanner {
	.video-and-text-wrapper {
		max-height: 650px;
	}
}

.dm-hide-mobile {
	@media (max-width: 980px) {
		display: none !important;
	}
	@media (min-width: 981px) {
		// display: contents !important;
	}
}

.dm-show-mobile {
	@media (max-width: 980px) {
		display: contents !important;
	}

	@media (min-width: 981px) {
		display: none !important;
	}
}

.child-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	img {
		max-width: 86%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}

.hero-section {
	position: relative;
}

.footer-small-text {
	color: #111111;
	font-size: 36px;
	font-weight: 300;
	display: inline-block;
	text-align: left;
	font-family: HalenoirCompact-ExtraLight, sans-serif;

	@media (max-width: 768px) {
		font-size: 20px;
	}
}

.h2-line-height {
	line-height: 150px;
	@media (max-width: 768px) {
		line-height: 48px;
	}

	@media (min-width: 769px) and (max-width: 1023px) {
		line-height: 75px;
	}

	@media (min-width: 1024px) and (max-width: 1440px) {
		line-height: 9vw;
	}
}

.responsibility-text {
	@media (max-width: 768px) {
		text-align: right;
		margin-top: 1.7vw;
		margin-right: 0;
	}
}

.expertiseHero {
	position: relative;
	.expertiseHero-section__heading {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: 2;

		h2 {
			position: relative;
			left: 25%;
		}
	}
	@media (max-width: 991px) {
		padding: 156px 20px 93px;
		background-color: rgba(0, 0, 0, 0.2);

		.expertiseHero-section__banner {
			position: absolute;
			inset: unset;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
		.expertiseHero-section__heading {
			max-height: unset;
			position: relative;
			transform: unset;

			h2 {
				position: relative;
				transform: unset;
				left: 20px;
			}
		}
		.expertiseHero-section__heding img {
			object-fit: cover;
			height: 100%;
		}
	}
}

.approachBanner {
	position: relative;
	overflow: hidden;
	.approachBanner-section__heading {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: 2;

		h2 {
			position: relative;
			left: 25%;
		}
	}
	@media (max-width: 991px) {
		padding: 156px 20px 93px;
		background-color: rgba(0, 0, 0, 0.2);

		.approachBanner-section__banner {
			position: absolute;
			inset: unset;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
		.approachBanner-section__heading {
			max-height: unset;
			position: relative;
			transform: unset;
			overflow: unset;

			h2 {
				position: relative;
				transform: unset;
				left: 20px;
			}
		}
		.approachBanner-section__heding img {
			object-fit: cover;
			height: 100%;
		}
	}
}

.communityBanner {
	position: relative;
	overflow: hidden;
	.communityBanner-section__heading {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		z-index: 2;

		h2 {
			position: relative;
			left: 25%;
		}
	}
	@media (max-width: 991px) {
		padding: 156px 20px 93px;
		background-color: rgba(0, 0, 0, 0.2);

		.communityBanner-section__banner {
			position: absolute;
			inset: unset;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
		}
		.communityBanner-section__heading {
			max-height: unset;
			position: relative;
			transform: unset;
			overflow: unset;

			h2 {
				position: relative;
				transform: unset;
				left: 20px;
			}
		}
		.communityBanner-section__heding img {
			object-fit: cover;
			height: 100%;
		}
	}
}
.expandCircle-section {
	@media (max-width: 768px) {
		background-color: rgba(0, 0, 0, 0.2);
		.expandCircle-section__heading {
			h2 {
				margin-bottom: 0;
			}
		}
		.expandCircle-section__video-wrapper img {
			object-fit: cover;
			height: 100%;
		}
		.new-standard-two {
			justify-content: flex-start !important;
			color: #fff;
		}
		.new-standard-three,
		.new-standard-one {
			margin-bottom: 0;
			color: #fff;
		}
	}
}

.logo-video-section__letter {
	h2 {
		&::after {
			content: "";
			display: block;
			height: 150;
		}
	}
}

.logo-video-wrapper div.full-stop {
	position: relative;
	max-width: 80px;
	max-height: 80px;
	margin-bottom: 14.6%;
	margin-right: 60px;
	flex-shrink: 0;
	display: block;
	width: 120px;
	height: 120px;

	&::after {
		content: "";
		display: block;
		height: 150px;
	}
	@media (max-width: 1281px) {
		width: 70px;
		height: 70px;
		margin-bottom: 22.6%;
	}

	@media (max-width: 768px) {
		width: 25px;
		height: 25px;
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 32.6%;
	}
}

.top-2 {
	padding-top: 3vw;
}

.dolor-sitamet-text-2 {
	margin-top: 15.5vw;

	@media (max-width: 768px) {
		margin-left: 2vw;
	}

	p {
		margin-top: 3vw;
	}
}

.dolor-sitamet-img-2 {
	display: block;
	margin-top: 35vw;

	img {
		width: 18.3vw;
		height: 12.9vw;
		margin-bottom: -12.5vw;
	}
}

.dolor-sitamet-img-4 {
	bottom: -7.3vw;
	left: -4.9vw;

	img {
		width: 22.7vw;
		height: 13.4vw;
	}
}

.dolor-sitamet-3 {
	padding-top: 6.9vw;
}

.new-standard-section {
	position: relative;

	.expandCircle-section__video {
		z-index: -1;
		position: absolute;
		inset: 0;
	}
}

.menu-open {
	.main-menu {
		overflow: hidden;
		overflow-y: scroll;
	}
}
.menu-btn {
	@media (max-width: 768px) {
		margin-right: 10px;
	}
}

.our-values-section {
	padding: 8vw 7.62vh;
	overflow: hidden;

	@media (min-width: 768px) and (max-width: 1250px) {
		padding: 8vw 5vw;
	}

	@media (max-width: 768px) {
		width: 100%;
		max-width: 768px;
		margin: 0 auto;
		//padding: 8vw 13vw;
		padding: 8vw 5vw;
	}
}

.our-mission-img-2,
.our-mission-img-1 {
	@media (max-width: 768px) {
		position: relative;
	}
}

.new-standard-one {
	@media (max-width: 767px) {
		margin-bottom: 40px;
	}
}

.new-standard-two {
	@media (max-width: 767px) {
		margin-bottom: 60px;
	}
}

/**/
.new-standard-three {
	&.dm-text {
		@media (min-width: 530px) and (max-width: 767px) {
			margin-bottom: 0px;
		}
	}
}

.our-values-text {
	&.dm-text-our-values {
		h3 {
			@media (min-width: 768px) and (max-width: 1440px) {
				font-size: 3.45vw;
			}

			@media (max-width: 530px) {
				font-size: 25px;
			}
		}
	}
}

.community-hero-image {
	&.dm-mobile-image {
		img {
			@media (max-width: 530px) {
				margin-top: 16vh;
			}
		}
	}
}

.our-values-first-row {
	&.dm-our-aproach-text {
		.text-character-a {
			@media (max-width: 768px) {
				margin-left: 0;
			}
		}
	}
}

.our-values-content-two-wrapper {
	display: grid;
}

.our-values-content-two {
	&.dm-our-values-content-2 {
		.our-values-content-5 {
			float: right;
			margin-left: 30%;
			margin-bottom: 30%;

			br {
				display: block;
			}
		}
	}
}

.color-white {
	color: #fff;
}
.founder-items {
	a {
		color: #ff8772;

		&:hover {
			color: #fff;
		}
	}
}
// ------------------
// ------------------
// ------------------
// ------------------
.ourApproachPage {
	.our-values-content-two-wrapper {
		.our-values-content-4 {
			max-width: 75%;
			margin-top: 15%;
			margin-bottom: 15%;
		}
	}
	.our-values-content-one-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		height: 100%;

		.our-values-content-1,
		.our-values-content-2 {
			max-width: 75%;
		}
		.our-values-content-2 {
			margin-left: 25%;
		}
	}
	.our-values-content-two {
		.our-values-text-character {
			position: relative;
			height: 100%;

			.text-character-t {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
			}
		}
	}
	.our-values-content-two,
	.our-values-content-one {
		gap: 50px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		align-items: center;

		@media (max-width: 1280px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}

	.our-values-first-row {
		margin-top: 110px;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		gap: 50px;

		@media (max-width: 1280px) {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	.our-values-section {
		max-width: 1440px;
		width: 100%;
		margin: 0 auto;
		padding: 0;
	}
	.text-character-t,
	.text-character-p {
		max-width: 650px;
		height: auto;
		margin-left: 0;
		font-size: 0;
	}
	.text-character-a {
		max-width: 650px;
		height: auto;
		margin-left: 0;
		font-size: 0;
		margin-top: 100px;
		margin-bottom: 100px;
	}

	@media only screen and (max-width: 870px) {
		/* CSS rules for small screens */
		.content-details,
		.content-title {
			margin-bottom: 50px;
		}

		.our-values-text h3 {
			height: unset;
			font-size: 32px;
		}

		.our-values-first-row {
			margin-top: 50px;
		}

		.our-values-content-one,
		.our-values-first-row {
			grid-template-columns: unset;
		}
		.our-values-text-character {
			display: none;
		}

		.our-values-content-one-wrapper .our-values-content-1 {
			max-width: unset;
		}

		.text-character-a {
			margin-top: 0;
			margin-bottom: 50px;
		}

		.our-values-content-1 {
			.content-title {
				font-size: 36px;
				font-weight: 300;
				line-height: 38.88px;
				margin-bottom: 50px;
			}
		}

		.our-values-content-one-wrapper .our-values-content-2 {
			margin-top: 50px;
			margin-left: 0;
			max-width: unset;
		}
		.our-values-content-two-wrapper .our-values-content-4 {
			max-width: unset;
			margin-top: 0;
		}
		.our-values-content-two {
			grid-template-columns: unset;
		}
		.our-values-content-two .our-values-text-character .text-character-t {
			position: relative;
		}
		.our-values-content-two.dm-our-values-content-2 .our-values-content-5 {
			margin-left: 0;
			margin-bottom: 0;
		}
	}
}

@media only screen and (min-width: 871px) {
	/* CSS rules for large screens */
	.showMobile {
		display: none;
	}
	.showDesktop {
		display: block;
	}
}

.communityPage {
	.social-responsibility-section {
		max-width: 1440px;
		margin: 0 auto;
		padding: 100px 50px;
	}

	@media only screen and (max-width: 870px) {
		/* CSS rules for small screens */
		.dolor-sitamet-section {
			padding: 79px 0 79px;
		}

		.dolor-sitamet-section {
			h2 {
				font-size: 54px;
				line-height: 48px;
				font-weight: 600;
				margin-bottom: 30px;
			}
			p {
				margin-left: 0;
				max-width: unset;
				margin-top: 0;
			}
		}

		.dolor-sitamet-text-1 {
			margin-left: 0;
			margin-top: 0;
		}

		.dolor-sitamet-text-2 {
			margin-left: 0;
			margin-top: 0;
			margin-bottom: 50px;
		}

		.dolor-sitamet-text-1,
		.dolor-sitamet-text-2,
		.dolor-sitamet-text-3 {
			padding: 0 30px;
		}

		.dolor-sitamet-text-3 {
			p {
				padding-top: 0;
				margin-bottom: 50px;
			}
		}

		.dolor-sitamet-img-3 {
			width: 80%;
			margin-left: auto;
			margin-bottom: 50px;
		}

		.dolor-sitamet-img-3,
		.dolor-sitamet-img-4 {
			position: static;
		}

		.dolor-sitamet-img-2,
		.dolor-sitamet-img-3,
		.dolor-sitamet-img-4 {
			img {
				width: 100%;
				height: auto;
			}
		}

		.dolor-sitamet-1 {
			flex-direction: column;
		}

		.dolor-sitamet-3 {
			max-width: unset;
			padding-top: 0;
		}

		.dolor-sitamet-3,
		.dolor-sitamet-2 {
			display: flex;
			flex-direction: column-reverse;
		}

		.dolor-sitamet-2 {
			margin-bottom: 50px;
		}

		.dolor-sitamet-img-2 {
			margin-top: 0;
			width: 80%;

			img {
				margin-bottom: 0;
			}
		}

		.dolor-sitamet-img-4 {
			margin-left: auto;
			width: 80%;
		}

		.dolor-sitamet-img-1,
		.dolor-sitamet-img-2,
		.dolor-sitamet-img-3,
		.dolor-sitamet-img-4 {
			height: 300px;

			img {
				margin-top: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.dolor-sitamet-img-1 {
			width: 80%;
			margin-bottom: 50px;
			overflow: hidden;
		}

		.community-hero-image {
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	@media only screen and (max-width: 420px) {
		.social-responsibility-section {
			padding: 50px 10px;
		}
		.dolor-sitamet-section {
			h2 {
				font-size: 40px;
			}
		}
	}
}

.homePage {
	.future-living-section {
		background: #111;

		.container {
			max-width: 1440px;
			margin: 0 auto;
			padding: 100px 50px;
		}
	}
	@media only screen and (max-width: 870px) {
		/* CSS rules for small screens */
		.future-living-section {
			h2 {
			}
			.container {
				padding: 50px 30px;
			}
		}
		.future-living-wrapper {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			h2 {
				right: unset;
				position: unset;
				transform: unset;
			}
			.future-living-image {
				margin-left: 0;
				margin-right: unset;
			}
		}
		.crafting-living-deatils {
			margin-top: 150px;
		}
		.crafting-living-deatils,
		.crafting-living-content {
			width: 100vw;
		}

		/* homepage word just */
		.new-standard-section {
			padding-top: 0;

			.new-standard-two {
				display: flex;
				justify-content: flex-end;
				margin-right: 0;
				gap: unset;
				white-space: pre;
				margin-bottom: 0;
			}
		}

		.new-standard-one {
			display: flex;
			justify-content: flex-end;
		}
	}
}

.aboutUsPage {
	@media only screen and (max-width: 870px) {
		/* CSS rules for small screens */
		.about-us-crafting-living-video .crafting-living-video-left .crafting-living-deatils {
			width: 100vw;
			margin-top: 100px;
		}
		.our-purpose-line-two {
			padding: 0 20px 69px;
			align-items: start;
			justify-content: start;
		}
	}
}

.ourExpertisePage {
	@media only screen and (max-width: 870px) {
		/* CSS rules for small screens */
		.view-our-team-image {
			max-width: unset;
			margin: 0 auto;
			left: unset;
			transform: unset;
			margin: 30px;
		}
		.view-our-team-content-4,
		.view-our-team-content-3 {
			max-width: unset;
		}
		.view-our-team-section {
			padding: 50px 20px 50px;
			max-width: 600px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

// RESPONSIVE HELPERS

@media only screen and (max-width: 870px) {
	/* CSS rules for small screens */
	.hideMobile {
		display: none !important;
	}
	.showMobile {
		display: block !important;
	}
}

.staticPage {
	.container {
		max-width: 980px;
		margin: 0 auto;
	}
	#main {
		h1 {
			font-size: 32px;
		}

		.container {
			padding: 0 20px;
			&:last-child {
				margin-bottom: 100px;
			}
			&:first-child {
				margin-top: 120px;

				@media (min-width: 1024px) {
					margin-top: 200px;
				}
			}
		}

		h2 {
			font-size: 24px;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		ul,
		p {
			margin-top: 40px;
		}
		a {
			text-decoration: underline;
			color: #111111;
		}
		li,
		p {
			line-height: 1.4;
		}

		ul {
			padding-left: 40px;
			li {
				list-style: initial;
			}
		}
	}
}
